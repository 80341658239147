import React from "react"

const Icon1 = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.48964 25.1875C3.74599 26.2634 4.90466 26.7964 5.96526 26.4827C10.1324 25.2501 15.3448 24.5176 21.0004 24.5176C26.6557 24.5176 31.8678 25.25 36.0349 26.4825C37.0954 26.7961 38.2541 26.2631 38.5104 25.1872C38.8305 23.8436 39 22.4416 39 21C39 11.0589 30.9411 3 21 3C11.0589 3 3 11.0589 3 21C3 22.4417 3.16949 23.8438 3.48964 25.1875Z"
      fill="#F1DEFA"
    />
    <path
      d="M29.6477 42.8258C28.879 44.9791 31.5487 46.9309 33.3247 45.2164L45.0561 33.8913C46.2592 32.7298 45.8115 30.7257 44.2689 30.1532L40.8566 28.8869L43.3355 21.9427C44.1007 19.7991 41.4585 17.8509 39.6757 19.5357L27.7023 30.851C26.479 32.0071 26.9205 34.0296 28.4723 34.6055L32.1013 35.9523L29.6477 42.8258ZM40.1709 28.6325C40.1712 28.6326 40.1715 28.6327 40.1718 28.6328L40.1709 28.6325Z"
      fill="#F67300"
      stroke="white"
      strokeWidth="3"
    />
    <rect x="15.499" y="30.9199" width="11" height="4" rx="2" fill="#F1DEFA" />
    <path
      d="M18.1542 25.125C18.9825 26.5597 20.8172 27.0512 22.2521 26.2227C23.687 25.3943 24.1787 23.5597 23.3504 22.125C22.5221 20.6904 20.6874 20.1989 19.2525 21.0273C17.8176 21.8558 17.3259 23.6904 18.1542 25.125Z"
      fill="#663399"
    />
    <path
      d="M34.201 15.1392C34.47 15.0347 34.7749 15.142 34.9192 15.3919C35.0628 15.6407 35.0045 15.9569 34.7817 16.1381L22.6851 25.9729L19.6851 20.7768L34.201 15.1392Z"
      fill="#663399"
    />
  </svg>
)

export default Icon1
