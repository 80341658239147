import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Header.module.css"

const NavItemGroup = ({ children }) => (
  <li className={styles.navigationMenu}>
    <ul className={styles.navigationItems}>{children}</ul>
  </li>
)

NavItemGroup.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavItemGroup
