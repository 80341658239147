import React from "react"
import * as styles from "./Why.module.css"
import Icon1 from "./Icon1"
import Icon2 from "./Icon2"
import Icon3 from "./Icon3"

const features = [
  {
    title: "Quản Lý",
    subTitle: "Từ Xa",
    icon: <Icon1 />,
    content:
      "Truy cập và thao tác với dữ liệu thực mọi lúc, mọi nơi. Tự động cập nhật và phân tích dữ liệu kinh doanh, thể hiện rõ sức khỏe phòng máy.",
  },
  {
    title: "Tối Ưu",
    subTitle: "Vận Hành",
    icon: <Icon2 />,
    color: "#0d96f2",
    content:
      "Dễ dàng cập nhật dịch vụ, sản phẩm, khuyến mại, cùng với các công cụ quản lý được tích hợp, giảm thiểu thời gian cài đặt, đào tạo nhân viên.",
  },
  {
    title: "Tối Đa",
    subTitle: "Doanh Thu",
    icon: <Icon3 />,
    color: "#bc027f",
    content:
      "Giảm thời gian chờ dịch vụ, giao diện thân thiện, quản lý dễ dàng, tạo tính chuyên nghiệp làm tăng hiệu suất sử dụng dịch vụ.",
  },
]

const Why = () => {
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        {features.map((i, idx) => (
          <div className={styles.item} key={idx}>
            <div>{i.icon}</div>
            <h2 className={styles.title}>{i.title}</h2>
            <h3 className={styles.subTitle} style={{ color: i.color }}>
              {i.subTitle}
            </h3>
            <div className={styles.content}>
              <p>{i.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Why
