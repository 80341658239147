import cn from "clsx"
import React from "react"
import { Link } from "gatsby"
import * as styles from "./Header.module.css"

const NavItem = ({ label, external = false, primary, secondary, ...rest }) => {
  if (external)
    return (
      <li className={styles.navigationItem}>
        <div className={styles.navigationItemResponsive}>
          <a className={styles.navigationLinkResponsive} {...rest}>
            {label}
          </a>
        </div>
        <a
          className={cn(
            styles.navigationLink,
            primary && styles.primaryLink,
            secondary && styles.secondaryLink
          )}
          {...rest}
        >
          {label}
        </a>
      </li>
    )

  return (
    <li className={styles.navigationItem}>
      <div className={styles.navigationItemResponsive}>
        <Link className={styles.navigationLinkResponsive} {...rest}>
          {label}
        </Link>
      </div>
      <Link className={styles.navigationLink} {...rest}>
        {label}
      </Link>
    </li>
  )
}

export default NavItem
