import { graphql, StaticQuery } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Img from "gatsby-image"
import React from "react"
// childImageSharp { gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF]) }
function QueryImage({ name, ...rest }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(filter: { name: { regex: "/f[0-9]/" } }) {
            nodes {
              name
              childImageSharp {
                fluid(maxWidth: 688) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      `}
      render={data => {
        const img = data.allFile.nodes.find(n => n.name === name)

        if (!img) {
          return null
        }

        // return <GatsbyImage image={getImage(img)} {...rest} />
        return <Img fluid={img.childImageSharp.fluid} {...rest} />
      }}
    />
  )
}

export default QueryImage
