import React from "react"
import { Link } from "gatsby"
import * as styles from "./Footer.module.css"

const Footer = () => (
  <div className={styles.container}>
    <footer className={styles.footer}>
      <div className={styles.copyright}>
        &copy; {new Date().getFullYear()} GCP
      </div>
      <div className={styles.linkContainer}>
        <Link className={styles.link} to="/dieu-khoan-dich-vu">
          Điều Khoản Dịch Vụ
        </Link>
        <Link className={styles.link} to="/chinh-sach-bao-mat">
          Chính Sách Bảo Mật
        </Link>
        <Link className={styles.link} to="/lien-he">
          Liên Hệ
        </Link>
      </div>
    </footer>
  </div>
)

export default Footer
