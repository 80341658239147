import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Masonry } from "masonic"
import React from "react"
import Srl, { SRLWrapper } from "simple-react-lightbox"

const MasSLR = React.forwardRef(({ children, ...rest }, ref) => (
  <div ref={ref} {...rest}>
    <Srl>
      <SRLWrapper
        options={{
          settings: {
            disableWheelControls: true,
            disablePanzoom: true,
          },
          buttons: {
            showAutoplayButton: false,
            showDownloadButton: false,
            showFullscreenButton: false,
            showThumbnailsButton: false,
          },
          caption: {
            showCaption: false,
          },
          thumbnails: {
            showThumbnails: false,
          },
          progressBar: {
            showProgressBar: false,
          },
        }}
      >
        {children}
      </SRLWrapper>
    </Srl>
  </div>
))

function Item({ data }) {
  return (
    <GatsbyImage
      style={{ cursor: "pointer", maxHeight: 250 }}
      image={data.childImageSharp.gatsbyImageData}
      alt={data.name}
    />
  )
}

function MasonryGallery() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allFile(
            filter: { sourceInstanceName: { eq: "gallery" } }
            sort: { fields: name }
          ) {
            nodes {
              name
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
              }
            }
          }
        }
      `}
      render={data => (
        <Masonry
          as={MasSLR}
          items={data.allFile.nodes}
          columnGutter={10}
          render={Item}
        />
      )}
    />
  )
}

export default MasonryGallery
