import cn from "clsx"
import { Link } from "gatsby"
import React, { useState } from "react"
import * as styles from "./Header.module.css"
import Logo from "./Logo"
import NavItem from "./NavItem"
import NavItemGroup from "./NavItemGroup"
import NavMenu from "./NavMenu"
import Phone from "./Phone"

const Header = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <header className={styles.header}>
      <nav className={styles.navigation}>
        <Link
          aria-current="page"
          aria-label="Trang chủ"
          className={styles.logo}
          to="/"
        >
          <Logo />
        </Link>
        <button
          type="button"
          aria-expanded={expanded}
          aria-label={expanded ? "Close" : "Open"}
          className={
            expanded ? styles.navigationToggleExpanded : styles.navigationToggle
          }
          onClick={() => setExpanded(!expanded)}
        >
          <span
            className={
              expanded
                ? styles.navigationToggleIconExpanded
                : styles.navigationToggleIcon
            }
          ></span>
        </button>
        <NavMenu expanded={expanded}>
          <NavItemGroup>
            <NavItem label="Bảng Giá" to="/bang-gia" />
            <NavItem
              label="Hướng Dẫn"
              href="https://huongdan.gcp.vn"
              target="_blank"
              rel="noopener noreferrer"
              external
            />
            <NavItem
              label="Facebook"
              href="https://www.facebook.com/vn.gcp"
              target="_blank"
              rel="noopener noreferrer"
              external
            />
            <NavItem
              label="Hợp Tác"
              href="https://www.facebook.com/vn.gcp"
              target="_blank"
              rel="noopener noreferrer"
              external
            />
          </NavItemGroup>
          <NavItemGroup>
            <NavItem
              label={
                <span className={styles.hotline}>
                  <Phone className={cn(styles.phone, styles.shake)} /> HTKT: 028
                  777 88 088
                </span>
              }
              href="tel:02877788088"
              external
              secondary
            />
            <NavItem
              label={
                <span className={styles.hotline}>
                  <Phone className={cn(styles.phone, styles.shake)} /> CSKH: 024
                  777 77 868
                </span>
              }
              href="tel:02477777868"
              external
              primary
            />
            <NavItem
              label="GCMenu ->"
              href="https://menu.gcp.vn"
              target="_blank"
              rel="noopener noreferrer"
              external
            />
          </NavItemGroup>
        </NavMenu>
      </nav>
    </header>
  )
}

export default Header
