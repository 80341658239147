import React from "react"

const Icon3 = () => (
  <svg
    width="47"
    height="47"
    viewBox="0 0 47 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="28.5" cy="18.5" r="18" fill="#F1DEFA"></circle>
    <circle
      cx="12"
      cy="35"
      r="10.5"
      fill="white"
      stroke="white"
      strokeWidth="3"
    ></circle>
    <circle
      cx="12"
      cy="35"
      r="7.5"
      fill="white"
      stroke="#663399"
      strokeWidth="3"
    ></circle>
    <path
      d="M16.8779 29.8477L29.8779 16.8477"
      stroke="#663399"
      strokeWidth="3"
      strokeLinejoin="round"
    ></path>
    <circle cx="12" cy="35" r="3.5" fill="#F67300"></circle>
    <path
      d="M34.5284 12.3888L30.718 26.6095L27.6341 19.2831L20.3076 16.1992L34.5284 12.3888Z"
      fill="#663399"
    ></path>
  </svg>
)

export default Icon3
