import React from "react"

const Logo = () => (
  <svg height="36" viewBox="0 0 509.5 276.9">
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="119.9"
        y1="1.1"
        x2="119.9"
        y2="278"
        gradientTransform="matrix(1, 0, 0, -1, 0, 278)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#bc2b2a" />
        <stop offset="1" stopColor="#d24827" />
      </linearGradient>
    </defs>
    <path
      d="M120.2,276.9h-.5a1.79,1.79,0,0,0-.7-.5Q59.9,242.2.8,208.1c-.3-.1-.5-.3-.8-.4V69.2a4.18,4.18,0,0,0,1-.4q20.85-12,41.7-24.1Q80.8,22.65,119,.6l.9-.6h.2a4.7,4.7,0,0,0,.9.6Q151.15,18,181.2,35.4c19.2,11.1,38.4,22.1,57.6,33.2a8.79,8.79,0,0,0,1,.6,4.23,4.23,0,0,0-.7.5c-11.3,6.5-22.6,13-33.8,19.5a.91.91,0,0,1-1.2,0c-1.9-1.1-3.9-2.2-5.8-3.4L120.9,41.1a1.51,1.51,0,0,0-1.7,0q-41.4,24-83,47.9a1.42,1.42,0,0,0-.8,1.4v95.8a1.34,1.34,0,0,0,.8,1.4q41.55,23.85,83,47.9a1.35,1.35,0,0,0,1.7,0c5.3-3.1,10.7-6.2,16-9.2,11.7-6.7,23.3-13.5,35-20.2l.9-.6a5.58,5.58,0,0,1-.8-.5c-3.4-1.9-6.7-3.9-10.1-5.8l-6.3-3.6c-.5-.3-.8-.4-1.3,0a.76.76,0,0,1-.4.2l-30.6,17.7c-1.1.6-2.2,1.3-3.3,1.9-.3-.2-.6-.3-.9-.5-21.7-12.5-43.5-25.1-65.2-37.6a1.34,1.34,0,0,1-.8-1.4V100.7a1.42,1.42,0,0,1,.8-1.4c21.8-12.5,43.5-25.1,65.3-37.6a5.58,5.58,0,0,0,.8-.5c22.3,12.8,44.5,25.6,66.8,38.5-.4.3-.8.5-1.1.7-11,6.3-21.9,12.7-32.9,19a1.35,1.35,0,0,1-1.7,0c-9.9-5.8-19.9-11.5-29.8-17.2-.4-.2-.8-.5-1.2-.7l-.6.3c-10.2,5.9-20.5,11.8-30.7,17.7a1.21,1.21,0,0,0-.7,1.3v35.1a1.21,1.21,0,0,0,.8,1.3c10.2,5.8,20.3,11.7,30.5,17.6a2.51,2.51,0,0,1,.7.4c.3-.2.6-.3.9-.5,8.9-5.2,17.8-10.3,26.7-15.5,1.2-.7,2.5-1.4,3.7-2.2.4-.2.7-.3,1,0a2.51,2.51,0,0,0,.7.4l34.8,20.1c12.4,7.1,24.7,14.3,37.1,21.4,4.9,2.9,9.9,5.7,14.8,8.5v.2a4.05,4.05,0,0,1-.9.5c-12.4,7.1-24.7,14.3-37.1,21.4q-40.35,23.25-80.8,46.6A3,3,0,0,1,120.2,276.9Z"
      fill="url(#linear-gradient)"
    />
    <path
      d="M309.4,137.8c0,15.3-5,28-15.1,38.2s-22.8,15.2-38,15.2q-22.05,0-37.5-15c-10.3-10.1-15.4-22.5-15.4-37.2s5.2-27,15.7-36.9c10.3-9.6,22.8-14.4,37.6-14.4a53,53,0,0,1,30.7,9.7c9.8,6.7,16.1,15.2,19.1,25.6H278q-7.2-12.9-21.9-12.9a25.38,25.38,0,0,0-19.7,8.5q-7.5,8.25-7.5,20.4c0,8.4,2.4,15.4,7.3,21,5.1,5.9,11.8,8.9,20,8.9a27.82,27.82,0,0,0,14.9-3.9,20.23,20.23,0,0,0,8.9-12.2H250.3V132.5h59.1c0,.8.1,1.7.1,2.6l-.1,2.7Z"
      fill="#282828"
    />
    <path
      d="M421.3,151.9A51.16,51.16,0,0,1,371,191.1c-14.5,0-26.9-5-37.1-15s-15.4-22.3-15.4-36.9,5.1-26.7,15.2-36.7,22.5-14.8,37-14.8q28.35,0,43.8,23.4a43,43,0,0,1,6.8,16.3H394.6c-4.4-11.6-12.4-17.3-24.1-17.3a24.75,24.75,0,0,0-19.4,8.7,29.79,29.79,0,0,0-7.3,20.3,30.56,30.56,0,0,0,7.3,20.6,24.55,24.55,0,0,0,19.5,9.1c10.9,0,18.8-5.6,23.9-16.8l26.8-.1Z"
      fill="#282828"
    />
    <path
      d="M506.8,137.4a30.68,30.68,0,0,1-7.9,12,32.36,32.36,0,0,1-13.6,7.1,63.85,63.85,0,0,1-15.4,1.5h-11v31.2H434.5V89.5h32.6c3.2,0,5.8.1,8,.2q4.5.3,8.1.9c6,1.1,10.9,3.1,14.6,6.2q11.7,9.9,11.7,26.4A41.11,41.11,0,0,1,506.8,137.4Zm-27-23.5c-2.5-1.3-6.4-2-11.6-2h-9.4v23.7h10.3c4.9,0,8.6-.7,11.1-2.2,3.2-1.9,4.8-5.1,4.8-9.7S483.3,115.7,479.8,113.9Z"
      fill="#282828"
    />
  </svg>
)

export default Logo
