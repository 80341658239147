import React, { Fragment } from "react"
import cn from "clsx"
import { Checked, Client, Manager, Other } from "../icons"
import * as styles from "./PricingPlan.module.css"

const features = [
  {
    group: "Phần Mềm Quản Lý",
    icon: <Manager />,
    features: [
      {
        name: "Truy Cập Từ Xa",
        description: "Truy cập bằng bất cứ thiết bị nào có kết nối internet",
        free: true,
        prox: true,
      },
      {
        name: "Xử Lý Đơn Hàng",
        description: "Tiếp nhận và xử lý đơn hàng theo thời gian thực",
        free: true,
        prox: true,
      },
      {
        name: "In Đơn Hàng",
        description: "In đơn hàng từ xa trên nhiều thiết bị khác nhau",
        free: true,
        prox: true,
      },
      {
        name: "Thiết Lập Khuyến Mại",
        description:
          "Tạo khuyến mại với nhiều hình thức nhập mã, giảm toàn menu, ...",
        free: true,
        prox: true,
      },
      {
        name: "Quản Lý Nhân Sự",
        description: "Thêm nhân viên, phân quyền nhân viên",
        free: true,
        prox: true,
      },
      {
        name: "Quản Lý Theo Ca",
        free: true,
        prox: true,
      },
      {
        name: "Quản Lý Kho",
        description: "Theo dõi số lượng tồn kho, nhập kho, quản lý giá nhập",
        free: false,
        prox: true,
      },
      {
        name: "Báo Cáo Kinh Doanh",
        free: true,
        prox: true,
      },
      {
        name: "Xuất Báo Cáo Kinh Doanh",
        description:
          "Xuất báo cáo dưới dạng bảng tính, dễ dàng in ấn, làm sổ sách",
        free: false,
        prox: true,
      },
      {
        name: "Thống Kê Số Liệu Phòng Máy",
        description:
          "Tổng quan các số liệu, biểu đồ về hoạt động kinh doanh phòng máy",
        free: false,
        prox: true,
      },
      {
        name: "Theo Dõi Máy Trạm",
        description: "Xem các máy trạm đang hoạt động, tiền giờ, CPU, GPU, ...",
        free: false,
        prox: true,
      },
    ],
  },
  {
    group: "Máy Trạm",
    icon: <Client />,
    features: [
      {
        name: "Menu Dịch Vụ",
        description: "Menu dịch vụ chuyên nghiệp, hấp dẫn",
        free: true,
        prox: true,
      },
      {
        name: "Menu Game",
        description:
          "Hoạt động với bất kỳ phần mềm update game hoặc game tự cài",
        free: true,
        prox: true,
      },
      {
        name: "GG Chat",
        description: "Tương tác với nhân viên, phòng máy và cộng đồng GCP",
        free: true,
        prox: true,
      },
    ],
  },
  {
    group: "Khác",
    icon: <Other />,
    features: [
      {
        name: "Đồng Bộ Dữ Liệu CSM/GCafe",
        description: "Đồng bộ dữ liệu tiền nạp, tài khoản, số dư từ CSM/GCafe",
        free: false,
        prox: true,
      },
      {
        name: "Hỗ Trợ Online",
        free: "Giờ Hành Chính",
        prox: "Hỗ Trợ 24/7",
      },
      {
        name: "Tích Hợp Ví Điện Tử",
        description: "MoMo, ViettelPay, ...",
        free: "",
        prox: "Liên Hệ",
      },
    ],
  },
]

const renderPlan = ({ key }) => (
  <table className={styles.pricingTableResponsive}>
    <thead>
      <tr>
        <th className={styles.header}>
          {key === "free" ? (
            <>
              <p className={cn(styles.plan, styles.planPro)}>
                GC<span>Menu</span>
              </p>
              <p className={styles.price}>Miễn Phí</p>
            </>
          ) : (
            <>
              <p className={cn(styles.plan, styles.planProX)}>
                GC<span>Menu</span>
                <em>X</em>
              </p>
              <p className={styles.price}>5.000₫ / máy / tháng</p>
            </>
          )}
        </th>
      </tr>
    </thead>
    <tbody>
      {features.map((groupItem, groupIndex) => (
        <Fragment key={groupIndex}>
          <tr>
            <th className={styles.featureGroup}>
              {groupItem.icon}
              <span className={styles.featureGroupLabel}>
                {groupItem.group}
              </span>
            </th>
          </tr>
          {groupItem.features.map(
            (item, index) =>
              item[key] && (
                <Fragment key={groupIndex + "_" + index}>
                  <tr className={styles.featureRow}>
                    <th className={styles.feature}>
                      <span>{item.name}</span>
                      <p className={styles.featureDescription}>
                        {item.description}
                      </p>
                    </th>
                  </tr>
                  <tr className={styles.featureRow}>
                    <td className={styles.featureCheck}>
                      {item[key] === true ? <Checked /> : item[key]}
                    </td>
                  </tr>
                </Fragment>
              )
          )}
        </Fragment>
      ))}
    </tbody>
  </table>
)

const PricingPlan = () => (
  <section className={styles.containerWrapper}>
    <div className={styles.container}>
      <header className={styles.headerContainer}>
        <h2 className={styles.headerTitle}>Tính năng & Bảng giá</h2>
        <div className={styles.headerDescContainer}>
          <p>
            Miễn phí <strong>2 tuần</strong> sử dụng thử phiên bản thu phí
            GCMenu
            <strong>X</strong>.
          </p>
          <p>Khuyến mãi thêm 1 tháng sử dụng khi thanh toán trước 12 tháng.</p>
          <p>
            Phần mềm update game <strong>GCUpdater</strong> đang trong giai đoạn
            thử nghiệm với số phòng máy giới hạn. Chính sách giá sẽ được cập
            nhật trong thời gian sắp tới.
          </p>
          <p>
            Có chính sách ưu đãi cho đại lý, kỹ thuật viên hoặc người giới
            thiệu, vui lòng liên hệ GCP để biết thêm chi tiết.
          </p>
        </div>
      </header>
      <table className={styles.pricingTable}>
        <thead>
          <tr>
            <th className={styles.header}>&nbsp;</th>
            <th className={styles.header}>
              <p className={cn(styles.plan, styles.planPro)}>
                GC<span>Menu</span>
              </p>
              <p className={styles.price}>Miễn Phí</p>
            </th>
            <th className={styles.header}>
              <p className={cn(styles.plan, styles.planProX)}>
                GC<span>Menu</span>
                <em>X</em>
              </p>
              <p className={styles.price}>5.000₫ / máy / tháng</p>
            </th>
          </tr>
        </thead>
        <tbody>
          {features.map((groupItem, groupIndex) => (
            <Fragment key={groupIndex}>
              <tr>
                <th className={styles.featureGroup} colSpan={3}>
                  {groupItem.icon}
                  <span className={styles.featureGroupLabel}>
                    {groupItem.group}
                  </span>
                </th>
              </tr>
              {groupItem.features.map((item, index) => (
                <tr key={index} className={styles.featureRow}>
                  <th className={styles.feature}>
                    <span>{item.name}</span>
                    <p className={styles.featureDescription}>
                      {item.description}
                    </p>
                  </th>
                  <td className={styles.featureCheck}>
                    {item.free === true ? <Checked /> : item.free}
                  </td>
                  <td className={styles.featureCheck}>
                    {item.prox === true ? <Checked /> : item.prox}
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
      {renderPlan({ key: "free" })}
      {renderPlan({ key: "prox" })}
    </div>
  </section>
)

export default PricingPlan
