import React from "react"
import Arrow from "./Arrow"
import * as styles from "./Cta.module.css"

const Cta = () => {
  return (
    <div className={styles.container}>
      <div className={styles.bg}>
        <h2 className={styles.title}>Chuyên nghiệp hóa phòng máy của bạn</h2>
        <p>
          Chỉ mất vài phút để cài đặt và sử dụng.
        </p>
        <a
          href="https://menu.gcp.vn"
          rel="noopener noreferrer"
          target="_blank"
          className={styles.action}
        >
          Đăng ký ngay <Arrow className={styles.arrow} />
        </a>
      </div>
    </div>
  )
}

export default Cta
