// extracted by mini-css-extract-plugin
export const header = "Header-module--header--AWpe5";
export const navigation = "Header-module--navigation--1vifJ";
export const logo = "Header-module--logo--2EZVS";
export const navigationLayout = "Header-module--navigationLayout--TIk7D";
export const navigationLayoutExpanded = "Header-module--navigationLayoutExpanded--32Zql";
export const navigationMenus = "Header-module--navigationMenus--ZCJ8m";
export const navigationMenusExpanded = "Header-module--navigationMenusExpanded--16Oyl";
export const navigationItems = "Header-module--navigationItems--G81lu";
export const navigationItem = "Header-module--navigationItem--3CsS1";
export const navigationLink = "Header-module--navigationLink--Zyf_y";
export const primaryLink = "Header-module--primaryLink--36f2k";
export const secondaryLink = "Header-module--secondaryLink--j-Har";
export const navigationItemResponsive = "Header-module--navigationItemResponsive--qso16";
export const navigationLinkResponsive = "Header-module--navigationLinkResponsive--3Qzw1";
export const navigationToggle = "Header-module--navigationToggle--tSQRo";
export const navigationToggleExpanded = "Header-module--navigationToggleExpanded--38sq5";
export const navigationToggleIcon = "Header-module--navigationToggleIcon--5qQ6N";
export const navigationToggleIconExpanded = "Header-module--navigationToggleIconExpanded--1wDZ0";
export const navigationMenu = "Header-module--navigationMenu--2K4Hk";
export const navigationExpanded = "Header-module--navigationExpanded--2TtGd";
export const hotline = "Header-module--hotline--1jJtd";
export const phone = "Header-module--phone--3r19X";
export const shake = "Header-module--shake--1jT8x";
export const shakeAnimation = "Header-module--shakeAnimation--CJJNW";