import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./Partner.module.css"

const Partner = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "partners" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                height: 80
                transformOptions: { grayscale: true }
              )
            }
          }
        }
      }
    }
  `)

  const renderImages = (ariaHidden = false) => {
    return (
      <ul aria-hidden={ariaHidden}>
        {data.allFile.edges.map((item, index) => (
          <li key={index}>
            <GatsbyImage
              image={item.node.childImageSharp.gatsbyImageData}
              alt=""
            />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <section className={styles.container}>
      <h2 className={styles.partnerHeadline}>
        Là phần mềm quản lý <strong>được yêu thích nhất</strong> của các phòng máy
      </h2>
      <div className={styles.partnerList}>
        {renderImages()}
        {renderImages(true)}
      </div>
    </section>
  )
}

export default Partner
