// extracted by mini-css-extract-plugin
export const containerWrapper = "PricingPlan-module--containerWrapper--3EOlK";
export const container = "PricingPlan-module--container--2guJJ";
export const headerContainer = "PricingPlan-module--headerContainer--2rVS9";
export const headerTitle = "PricingPlan-module--headerTitle--1iBuY";
export const headerDescContainer = "PricingPlan-module--headerDescContainer--G8VJb";
export const pricingTable = "PricingPlan-module--pricingTable--2flWo";
export const header = "PricingPlan-module--header--eN5yw";
export const featureRow = "PricingPlan-module--featureRow--30K9e";
export const feature = "PricingPlan-module--feature--DhoMJ";
export const featureCheck = "PricingPlan-module--featureCheck--13XPh";
export const plan = "PricingPlan-module--plan--2lRWZ";
export const planPro = "PricingPlan-module--planPro--3_LsW";
export const planProX = "PricingPlan-module--planProX--oTfGi";
export const price = "PricingPlan-module--price--LlVdr";
export const checked = "PricingPlan-module--checked--3eZh9";
export const featureGroup = "PricingPlan-module--featureGroup--1ZkUJ";
export const featureGroupLabel = "PricingPlan-module--featureGroupLabel--3Uzab";
export const pricingTableResponsive = "PricingPlan-module--pricingTableResponsive--nLdtW";
export const featureDescription = "PricingPlan-module--featureDescription--X78Lr";