import React from "react"
import * as styles from "./Gallery.module.css"
import MasonryGallery from "./MasonryGalerry"

const Gallery = () => {
  return (
    <section className={styles.container}>
      <h2 className={styles.partnerHeadline}>Thư Viện Hình Ảnh</h2>
      <div className={styles.headerDescContainer}>
        <p>Một số hình ảnh giao diện khi sử dụng phần mềm GCP.</p>
      </div>
      <MasonryGallery />
    </section>
  )
}

export default Gallery
