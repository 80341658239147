import cn from "clsx"
import React, { useState } from "react"
import Chevron from "./Chevron"
import ChevronRight from "./ChevronRight"
import * as styles from "./Feature.module.css"
import Icon1 from "./Icon1"
import QueryImage from "./QueryImage"

const features = [
  {
    title: "GCMenu",
    note: "Miễn Phí",
    desc:
      "Menu dịch vụ tích hợp công cụ quản lý giúp chủ phòng máy trong việc kinh doanh dễ dàng, hiệu quả. Phiên bản GCMenu miễn phí và không giới hạn máy trạm phù hợp với các phòng máy vừa và nhỏ. Với các phòng máy lớn, phiên bản nâng cấp GCMenuX sẽ là sự lựa chọn hợp lý.",
    media: "f1",
    button: {
      label: "Đăng ký ngay",
      link: "https://menu.gcp.vn/",
    },
  },
  {
    title: "GCUpdater",
    note: "Beta",
    desc:
      "Phần mềm update game với kho game đa dạng được cập nhật liên tục. Menu game tích hợp vào menu dịch vụ. Liên hệ chăm sóc khách hàng GCP để được trải nghiệm trước GCUpdater.",
    media: "f2",
  },
  {
    title: "Sự Kiện Phòng Máy",
    note: "Beta",
    desc:
      "Dễ dàng tạo các sự kiện trúng thưởng, tri ân khách hàng, giúp kết nối cộng đồng game thủ đồng thời giúp tăng doanh thu phòng máy.",
    media: "f3",
  },
  {
    title: "PUBG Store VN",
    desc:
      "Chuyên trang độc quyền phân phối mã kích hoạt, mã quà tặng của PUBG tại Việt Nam. Với nhiều chính sách ưu đãi hấp dẫn cho đại lý.",
    media: "f5",
    button: {
      label: "PUBG Store VN",
      link: "https://pubg.gcp.vn/",
    },
  },
]

const Feature = () => {
  const [expanded, setExpanded] = useState(0)

  return (
    <section className={styles.container}>
      <header className={styles.headerContainer}>
        <h2 className={styles.headerTitle}>Giải Pháp</h2>
        <div className={styles.headerDescContainer}>
          <p>
            Với nhiều tính năng mà các phần mềm hiện tại trên thị trường không
            có, GCP tự tin giúp chủ phòng máy quản lý, theo dõi tình hình kinh
            doanh phòng máy một cách chuyên nghiệp, dễ dàng và hiệu quả.
          </p>
        </div>
      </header>
      <div className={styles.featureWrapper}>
        <div className={styles.featureContent}>
          {features.map((i, idx) => (
            <div
              className={cn(
                styles.featureItem,
                expanded === idx && styles.expanded
              )}
              key={idx}
            >
              <h3 className={styles.featureTitle}>
                <Icon1 className={styles.featureIcon} />
                <button onClick={() => setExpanded(idx)}>
                  <span>
                    {i.title}
                    {i.note && <small>{i.note}</small>}
                  </span>
                  <span className={styles.chevron}>
                    <Chevron />
                  </span>
                </button>
              </h3>
              <div role="region" className={styles.featureDesc}>
                <p>{i.desc}</p>
                {i.button && (
                  <a
                    className={styles.featureButton}
                    href={i.button.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i.button.label}
                    <ChevronRight style={{ marginTop: 4 }} />
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
        <div className={styles.featureMediaWrapper}>
          <QueryImage
            name={features[expanded].media}
            alt=""
            objectFit="cover"
            objectPosition="50% 50%"
            className={styles.featureImgWrapper}
          />
        </div>
      </div>
    </section>
  )
}

export default Feature
