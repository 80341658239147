import React from "react"

const Icon2 = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
      stroke="#F1DEFA"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></path>
    <path
      d="M32.9996 17.5202C30.1808 18.2762 26.876 18.6002 23.8196 18.6002C20.7632 18.6002 17.4584 18.2762 14.6396 17.5202L14.0996 19.6802C16.1084 20.2202 18.4196 20.5766 20.5796 20.7602V34.8002H22.7396V28.3202H24.8996V34.8002H27.0596V20.7602C29.2196 20.5766 31.5308 20.2202 33.5396 19.6802L32.9996 17.5202ZM23.8196 17.5202C25.0076 17.5202 25.9796 16.5482 25.9796 15.3602C25.9796 14.1722 25.0076 13.2002 23.8196 13.2002C22.6316 13.2002 21.6596 14.1722 21.6596 15.3602C21.6596 16.5482 22.6316 17.5202 23.8196 17.5202Z"
      fill="#663399"
    ></path>
    <circle
      cx="36"
      cy="38"
      r="3.5"
      fill="#F67300"
      stroke="white"
      strokeWidth="3"
    ></circle>
    <circle
      cx="35.5"
      cy="9.5"
      r="5"
      fill="#F67300"
      stroke="white"
      strokeWidth="3"
    ></circle>
    <circle
      cx="7"
      cy="30"
      r="4.5"
      fill="#F67300"
      stroke="white"
      strokeWidth="3"
    ></circle>
  </svg>
)

export default Icon2
