// extracted by mini-css-extract-plugin
export const container = "Feature-module--container--3UeeM";
export const headerContainer = "Feature-module--headerContainer--1WITz";
export const headerTitle = "Feature-module--headerTitle--1TC6s";
export const headerDescContainer = "Feature-module--headerDescContainer--2SXIp";
export const featureWrapper = "Feature-module--featureWrapper--1pGsE";
export const featureContent = "Feature-module--featureContent--1wdvi";
export const featureItem = "Feature-module--featureItem--nFT9n";
export const featureTitle = "Feature-module--featureTitle--1KU47";
export const featureIcon = "Feature-module--featureIcon--2uwyV";
export const chevron = "Feature-module--chevron--334QD";
export const expanded = "Feature-module--expanded--tGkgj";
export const featureDesc = "Feature-module--featureDesc--2OMaa";
export const featureMediaWrapper = "Feature-module--featureMediaWrapper--2a2n2";
export const featureImgWrapper = "Feature-module--featureImgWrapper--2_JIt";
export const featureButton = "Feature-module--featureButton--1ehax";