import React from "react"

const Client = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    version="1"
    viewBox="0 0 48 48"
    enableBackground="new 0 0 48 48"
    height="24px"
    width="24px"
    style={{ verticalAlign: "middle" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#FFA726">
      <circle cx="10" cy="26" r="4"></circle>
      <circle cx="38" cy="26" r="4"></circle>
    </g>
    <path
      fill="#FFB74D"
      d="M39,19c0-12.7-30-8.3-30,0c0,1.8,0,8.2,0,10c0,8.3,6.7,15,15,15s15-6.7,15-15C39,27.2,39,20.8,39,19z"
    ></path>
    <path
      fill="#FF5722"
      d="M24,3C14.6,3,7,10.6,7,20c0,1.2,0,3.4,0,3.4L9,25v-3l21-9.8l9,9.8v3l2-1.6c0,0,0-2.1,0-3.4 C41,12,35.3,3,24,3z"
    ></path>
    <g fill="#784719">
      <circle cx="31" cy="26" r="2"></circle>
      <circle cx="17" cy="26" r="2"></circle>
    </g>
    <path
      fill="#757575"
      d="M43,24c-0.6,0-1,0.4-1,1v-7c0-8.8-7.2-16-16-16h-7c-0.6,0-1,0.4-1,1s0.4,1,1,1h7c7.7,0,14,6.3,14,14v10 c0,0.6,0.4,1,1,1s1-0.4,1-1v2c0,3.9-3.1,7-7,7H24c-0.6,0-1,0.4-1,1s0.4,1,1,1h11c5,0,9-4,9-9v-5C44,24.4,43.6,24,43,24z"
    ></path>
    <g fill="#37474F">
      <path d="M43,22h-1c-1.1,0-2,0.9-2,2v4c0,1.1,0.9,2,2,2h1c1.1,0,2-0.9,2-2v-4C45,22.9,44.1,22,43,22z"></path>
      <circle cx="24" cy="38" r="2"></circle>
    </g>
  </svg>
)

const Manager = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    version="1"
    viewBox="0 0 48 48"
    enableBackground="new 0 0 48 48"
    height="24px"
    width="24px"
    style={{ verticalAlign: "middle" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="5" y="19" fill="#CFD8DC" width="38" height="19"></rect>
    <rect x="5" y="38" fill="#B0BEC5" width="38" height="4"></rect>
    <rect x="27" y="24" fill="#455A64" width="12" height="18"></rect>
    <rect x="9" y="24" fill="#E3F2FD" width="14" height="11"></rect>
    <rect x="10" y="25" fill="#1E88E5" width="12" height="9"></rect>
    <path
      fill="#90A4AE"
      d="M36.5,33.5c-0.3,0-0.5,0.2-0.5,0.5v2c0,0.3,0.2,0.5,0.5,0.5S37,36.3,37,36v-2C37,33.7,36.8,33.5,36.5,33.5z"
    ></path>
    <g fill="#558B2F">
      <circle cx="24" cy="19" r="3"></circle>
      <circle cx="36" cy="19" r="3"></circle>
      <circle cx="12" cy="19" r="3"></circle>
    </g>
    <path
      fill="#7CB342"
      d="M40,6H8C6.9,6,6,6.9,6,8v3h36V8C42,6.9,41.1,6,40,6z"
    ></path>
    <rect x="21" y="11" fill="#7CB342" width="6" height="8"></rect>
    <polygon fill="#7CB342" points="37,11 32,11 33,19 39,19"></polygon>
    <polygon fill="#7CB342" points="11,11 16,11 15,19 9,19"></polygon>
    <g fill="#FFA000">
      <circle cx="30" cy="19" r="3"></circle>
      <path d="M45,19c0,1.7-1.3,3-3,3s-3-1.3-3-3s1.3-3,3-3L45,19z"></path>
      <circle cx="18" cy="19" r="3"></circle>
      <path d="M3,19c0,1.7,1.3,3,3,3s3-1.3,3-3s-1.3-3-3-3L3,19z"></path>
    </g>
    <g fill="#FFC107">
      <polygon points="32,11 27,11 27,19 33,19"></polygon>
      <polygon points="42,11 37,11 39,19 45,19"></polygon>
      <polygon points="16,11 21,11 21,19 15,19"></polygon>
      <polygon points="6,11 11,11 9,19 3,19"></polygon>
    </g>
  </svg>
)

const Other = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    version="1"
    viewBox="0 0 48 48"
    enableBackground="new 0 0 48 48"
    height="24px"
    width="24px"
    style={{ verticalAlign: "middle" }}
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      fill="#FFC107"
      points="33,22 23.6,22 30,5 19,5 13,26 21.6,26 17,45"
    ></polygon>
  </svg>
)

const Checked = () => (
  <svg
    stroke="currentColor"
    fill="currentColor"
    strokeWidth="0"
    viewBox="0 0 24 24"
    aria-label="Included"
    className="checked"
    height="1em"
    width="1em"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
  </svg>
)

export { Checked, Client, Manager, Other }
