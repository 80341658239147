import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Arrow from "../Cta/Arrow"
// import Video from "../Video"
import * as styles from "./Introduction.module.css"

const Introduction = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "kiin.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
        }
      }
    }
  `)

  return (
    <div className={styles.container}>
      <header className={styles.intro}>
        <h1 className={styles.introHeader}>
          Chuyên nghiệp hóa quy trình <span>vận hành phòng máy</span>
        </h1>
        <p className={styles.introDescription}>
          Là phần mềm đầu tiên trên thị trường được đầu tư bài bản, cung cấp{" "}
          <strong>giải pháp toàn diện</strong> cho chủ phòng máy trong việc kinh
          doanh và quản lý hiệu quả.
        </p>
        <p className={styles.introDescription} style={{ marginTop: "1rem" }}>
          Nay đã có thêm phiên bản <strong>miễn phí</strong>.
        </p>
        <div className={styles.introAction}>
          <a
            className={styles.introButton}
            href="https://menu.gcp.vn"
            target="_blank"
            rel="noopener noreferrer"
          >
            Đăng ký ngay <Arrow style={{ marginLeft: 4, marginTop: 4 }} />
          </a>
          <span className={styles.introNote}>hoặc liên hệ đội ngũ CSKH để được tư vấn thêm.</span>
        </div>
      </header>
      <div className={styles.introMedia}>
        <GatsbyImage
          image={data.file.childImageSharp.gatsbyImageData}
          alt="GCP - Giải Pháp Vận Hành Phòng Net Toàn Diện."
        />
        {/* <div
          style={{ position: "relative", width: "100%", paddingTop: "56.25%" }}
        >
          <Video
            videoSrcURL="https://www.youtube.com/embed/Jpm_BOhWmbw?controls=1&rel=0&autoplay=0&loop=1"
            videoTitle="dsaa"
          />
        </div> */}
      </div>
    </div>
  )
}

export default Introduction
