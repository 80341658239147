import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./OtherPartner.module.css"

const OtherPartner = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { sourceInstanceName: { eq: "partners2" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                height: 80
                transformOptions: { grayscale: true }
              )
            }
          }
        }
      }
    }
  `)

  const renderImages = () => {
    return (
      <ul>
        {data.allFile.edges.map((item, index) => (
          <li key={index}>
            <GatsbyImage
              style={{ opacity: "0.8", marginBottom: '1.5rem' }}
              image={item.node.childImageSharp.gatsbyImageData}
              alt=""
            />
          </li>
        ))}
      </ul>
    )
  }

  return (
    <section className={styles.container}>
      <h2 className={styles.partnerHeadline}>Đối Tác</h2>
      <div className={styles.headerDescContainer}>
        <p>GCP được tin tưởng bởi các đối tác lớn.</p>
      </div>
      <div className={styles.partnerList}>{renderImages()}</div>
    </section>
  )
}

export default OtherPartner
