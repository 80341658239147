import React from "react"
import PropTypes from "prop-types"
import * as styles from "./Header.module.css"

const NavMenu = ({ expanded = false, children }) => (
  <div
    className={
      expanded ? styles.navigationLayoutExpanded : styles.navigationLayout
    }
  >
    <ul
      className={
        expanded ? styles.navigationMenusExpanded : styles.navigationMenus
      }
    >
      {children}
    </ul>
  </div>
)

NavMenu.propTypes = {
  children: PropTypes.node.isRequired,
}

export default NavMenu
